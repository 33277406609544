.page-item {
    align-items: flex-start;
    background-color: var(--collor-grey-50);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 16px 24px;
    position: relative;
  }
  
  .page-item .page-item-2 {
    background-color: #ffffff;
    height: 159px;
    position: relative;
    width: 112px;
  }

  .page-item .page-item-2-landscape {
    background-color: #ffffff;
    height: 112px;
    position: relative;
    width: 159px;
  }

  .page-item .selectedPage {
    border: var(--collor-brand-500) solid 1px;
  }
  
  .page-item .header-2 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    left: 8px;
    position: absolute;
    top: 8px;
    width: 97px;
  }
  
  .page-item .logo-2 {
    position: relative;
    width: 25px;
  }
  
  .page-item .text-wrapper-11 {
    color: var(--collor-grey-700);
    font-family: "Space Grotesk", Helvetica;
    font-size: 2.3px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .page-item .frame-4 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    left: 8px;
    position: absolute;
    top: 151px;
    width: 97px;
  }
  
  .page-item .text-wrapper-12 {
    color: var(--collor-grey-700);
    font-family: "Space Grotesk", Helvetica;
    font-size: 2.3px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.19px;
    position: relative;
    width: fit-content;
  }
  
  .page-item .list-photos {
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 2px 2px;
    left: 8px;
    position: absolute;
    top: 25px;
    width: 97px;
  }
  .page-item .list-photos-landscape {
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 2px 2px;
    left: 8px;
    position: absolute;
    top: 25px;
    width: 140px;
  }
  
  .page-item .rectangle {
    background-color: #d9d9d9;
    height: 54.25px;
    position: relative;
    width: 31px;
  }

  .page-item .rectangle-6 {
    background-color: #d9d9d9;
    height: 54.25px;
    position: relative;
    width: 31px;
    border-radius: 0px;
  }
  .page-item .rectangle-5 {
    background-color: #d9d9d9;
    height: 54.25px;
    position: relative;
    width: 31px;
    border-radius: 0px;
  }
  .page-item .rectangle-4 {
    background-color: #d9d9d9;
    height: 58px;
    position: relative;
    width: 46px;
    border-radius: 0px;
  }
  .page-item .rectangle-3 {
    background-color: #d9d9d9;
    height: 38px;
    position: relative;
    width: 94px;
    border-radius: 0px;
  }
  .page-item .rectangle-2 {
    background-color: #d9d9d9;
    height: 58px;
    position: relative;
    width: 94px;
    border-radius: 0px;
    top: 0px;
  }
  .page-item .rectangle-1 {
    background-color: #d9d9d9;
    height: 120px;
    position: relative;
    width: 96px;
    border-radius: 0px;
  }

  .page-item .rectangle-landscape {
    background-color: #d9d9d9;
    height: 35px;
    position: relative;
    width: 44px;
  }

  .page-item .rectangle-landscape-6 {
    background-color: #d9d9d9;
    height: 35px;
    position: relative;
    width: 44px;
    border-radius: 0px;
  }
  .page-item .rectangle-landscape-5 {
    background-color: #d9d9d9;
    height: 35px;
    position: relative;
    width: 44px;
    border-radius: 0px;
  }
  .page-item .rectangle-landscape-4 {
    background-color: #d9d9d9;
    height: 35px;
    position: relative;
    width: 68px;
    border-radius: 0px;
  }
  .page-item .rectangle-landscape-3 {
    background-color: #d9d9d9;
    height: 75px;
    position: relative;
    width: 45px;
    border-radius: 0px;
  }
  .page-item .rectangle-landscape-2 {
    background-color: #d9d9d9;
    height: 75px;
    position: relative;
    width: 68px;
    border-radius: 0px;
  }
  .page-item .rectangle-landscape-1 {
    background-color: #d9d9d9;
    height: 75px;
    position: relative;
    width: 138px;
    border-radius: 0px;
  }
  
  .page-item .text-wrapper-13 {
    color: var(--collor-grey-700);
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 0px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 17px;
    white-space: nowrap;
  }

  .page-item .text-wrapper-13-landscape {
    color: var(--collor-grey-700);
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 0px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: -8px;
    left: 24px;
    white-space: nowrap;
  }