.princing {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  margin-top: 32px;
  width: 100%;
  overflow: hidden;
}

.princing .tabmenu-price {
  align-items: center;
  background-color: var(--collor-brand-200);
  border-radius: 80px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  padding: 8px;
  position: relative;
}

.princing .mensal {
  align-items: center;
  border-radius: 120px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 8px 16px;
  position: relative;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.princing .text-wrapper-2 {
  font-family: "Space Grotesk", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.princing .anual {
  align-items: center;
  border-radius: 120px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 8px 16px;
  position: relative;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.princing .text-wrapper-3 {
  font-family: "Space Grotesk", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.princing .list-price {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
  width: 1096px;
  height: 655px;
}

.princing .card-price-instance {
  flex: 1 !important;
  flex-grow: 1 !important;
  /* width: unset !important; */
  box-sizing: border-box;
}

.princing .instance-node {
  color: var(--collor-brand-800) !important;
}

.princing .card-price-2 {
  background-color: var(--collor-brand-400) !important;
}

.princing .card-price-3 {
  background-color: var(--collor-brand-200) !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  box-sizing: border-box;
  /* width: unset !important; */
}

.princing .default {
  background-color: var(--collor-brand-800);
}

.princing .variant-2 {
  background-color: var(--collor-brand-800);
}

.princing .mensal.default .text-wrapper-2 {
  color: var(--collor-brand-900);
}

.princing .mensal.variant-2 .text-wrapper-2 {
  color: #ffffff;
}

.princing .anual.default .text-wrapper-3 {
  color: var(--collor-brand-900);
}

.princing .anual.variant-2 .text-wrapper-3 {
  color: #ffffff;
}

@media screen and (max-width: 1120px) {
  .princing .list-price {
    width: 100%;
    overflow-x: scroll;
    box-sizing: border-box;
    padding: 0px 16px 16px 16px;
    height: 679px;;
  }
}
