.num-photo-page {
    align-items: center;
    border: 2.5px solid;
    border-radius: 2.5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 40px;
    justify-content: center;
    position: relative;
    width: 40px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    border-color: var(--collor-grey-900);
}

.num-photo-page .element {
    font-family: "Space Grotesk", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
    border-color: var(--collor-grey-900);
    color: var(--collor-grey-900);
}

.num-photo-page.selected {
    border-color: var(--collor-brand-500);
}

.num-photo-page.selected .element {
    border-color: var(--collor-brand-500);
    color: var(--collor-brand-500);
}
