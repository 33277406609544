.card-price {
  align-items: center;
  border: 1px solid;
  border-color: var(--collor-brand-300);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 400px;
  padding: 32px;
  position: relative;
  width: 360px;
  height: 100%;
}

.card-price .div {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.card-price .starded {
  color: var(--collor-brand-800);
  font-family: "Space Grotesk", Helvetica;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 35.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.card-price .price {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  min-height: 80px;
  position: relative;
}

.card-price .gr-tis {
  color: var(--collor-brand-800);
  font-family: "Space Grotesk", Helvetica;
  font-size: 56px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 61.6px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.card-price .users-three {
  height: 80px;
  position: relative;
  width: 80px;
}

.card-price .text-wrapper {
  color: var(--collor-brand-800);
  font-family: "Space Grotesk", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15.4px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.card-price .btn-instance {
  flex: 0 0 auto !important;
}

.card-price .rectangle {
  align-self: stretch;
  background-color: var(--collor-grey-200);
  height: 1px;
  position: relative;
  width: 100%;
}

.card-price .text-description {
  align-self: stretch;
  color: var(--collor-brand-600);
  font-family: "Space Grotesk", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22px;
  position: relative;
  text-align: center;
}

.card-price .item-description {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.card-price .check {
  height: 24px;
  position: relative;
  width: 24px;
}

.card-price .text-description-2 {
  color: var(--collor-grey-600);
  flex: 1;
  font-family: "Space Grotesk", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.6px;
  margin-top: -1px;
  position: relative;
}


@media screen and (max-width: 1120px) {
  .card-price {
    width: 227px;
    min-width: 227px;

    padding: 32px 16px;
  }
}