.add-picture button {
  align-items: center;
  border: 1px none;
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  padding: 8px 0px;
  position: relative;
  align-self: flex-start;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  background-color: transparent;
}

.add-picture .text-wrapper {
  color: var(--collor-brand-500);
  font-family: "Space Grotesk-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.add-picture .plus {
  height: 20px;
  position: relative;
  width: 20px;
}

.add-picture input[type="file"] {
  display: none;
}