.page-pdf {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 531px;
    height: 750px;
    margin-bottom: 24px;
}

.page-pdf-landscape {
  width: 750px;
  height: 531px;
}
  
  .page-pdf .relatorio-x {
    background-color: #ffffff;
    border: 1px none;
    height: 100%;
    position: relative;
    width:  100%;
    padding: 5% 4%;
    box-sizing: border-box;
  }

  .page-pdf .relatorio-x-landscape {
    background-color: #ffffff;
    border: 1px none;
    height: 100%;
    position: relative;
    width: 100%;
    padding: 5% 4%;
    box-sizing: border-box;
    
  }
  
  .page-pdf .header {
    align-items: center;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .page-pdf .logo {
    position: relative;
    height: 23px;
    width: auto;
  }
  
  .page-pdf .text-wrapper {
    color: var(--collor-grey-700);
    font-family: "Space Grotesk", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .page-pdf .frame-rodape {
    align-items: center;
    display: flex;
    justify-content: space-between;    
    width: 100%;
  }
  
  .page-pdf .div {
    color: var(--collor-grey-700);
    font-family: "Space Grotesk", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .page-pdf .list-photos-preview {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;  
    gap: 2%;
    width: 100%;
    height: 95%;
    margin-top: 8px;
  }

  .page-pdf .list-photos-preview-landscape {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;  
    gap: 10px;
    width: 100%;
    height: 95%;
    margin-top: 8px;
  }

  .page-pdf .summaryContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;  
    gap: 16px;
    width: 100%;
    height: 95%;
    margin-top: 8px;
  }

  .page-pdf .summaryContainer-landscape {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;  
    gap: 16px;
    width: 100%;
    height: 95%;
    margin-top: 8px;
  }

  .page-pdf .summaryTitle p {
    color: #000000;
    font-family: "Space Grotesk", Helvetica;
    font-size: 24px;
    font-weight: 400;
    height: 31px;
    left: 0;
    letter-spacing: 0;
    line-height: 31.2px;
    white-space: nowrap;
    margin: 24px 0 0 0; 
  }
  
  .page-pdf .summaryLine {
    align-items: flex-end;
    display: flex;
    gap: 4px;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  
  .page-pdf .summaryLineName {
    color: var(--collor-grey-700);
    font-family: "Space Grotesk", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .page-pdf .summaryLineDots {
    border: 1px dashed;
    border-color: var(--collor-grey-400);
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    flex: 1;
    flex-grow: 1;
    height: 2px;
    position: relative;
  }
  
  .page-pdf .summaryLineNumber {
    color: var(--collor-grey-600);
    font-family: "Space Grotesk", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: right;
    width: fit-content;
  }