.input-rodape {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--collor-grey-300);
  display: flex;
  gap: 8px;
  padding: 16px 0px;
  position: relative;
  width: 100%;
}

.input-rodape .title {
  display: flex;
  gap: 8px;
  position: relative;
}

.input-rodape .text-wrapper {
  color: var(--collor-grey-600);
  font-family: "Space Grotesk", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.input-rodape .text-wrapper-2 {
  align-self: stretch;
  color: var(--collor-grey-600);
  flex: 1;
  font-family: "Space Grotesk", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  resize: none;
  border: none;
  outline: none;
  background-color: var(--collor-grey-200);
}

.input-rodape .text-wrapper-3 {
  color: var(--collor-grey-600);
  font-family: "Space Grotesk", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: right;
  width: fit-content;
}

.input-rodape .note-pencil {
  position: relative;
}

.input-rodape.property-1-default {
  align-items: center;
}

.input-rodape.property-1-active {
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}

.input-rodape.property-1-default .title {
  align-items: center;
  flex: 1;
  flex-grow: 1;
}

.input-rodape.property-1-active .title {
  align-items: flex-end;
  align-self: stretch;
  background-color: var(--collor-grey-200);
  border: 1px solid;
  border-color: var(--collor-grey-400);
  border-radius: 16px;
  height: 55px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}

.input-rodape.property-1-default .note-pencil {
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.input-rodape.property-1-active .note-pencil {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.input-rodape .default-container{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-rodape .p {
  color: var(--collor-grey-700);
  flex: 1 1;
  font-family: "Space Grotesk", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}