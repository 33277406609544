.LoadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;  
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoadingScreen .logoContainer {
  width: 100%;
  height: 100vh;
  background-color: #FFFFFFAA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LoadingScreen .loadingImg{
    width: 100px;
    height: 100px;
    /* animation: spin 2s linear infinite; */
}

