.page-preview {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 595px;
    height: 892px;
}

.page-preview-landscape {
    width: 892px;
    height: 595px;
}

.page-preview .relatorio-x {
    background-color: #ffffff;
    border: 1px none;
    height: 100%;
    position: relative;
    width: 100%;
    padding: 5% 4%;
    box-sizing: border-box;
}

.page-preview .relatorio-x-landscape {
    background-color: #ffffff;
    border: 1px none;
    height: 100%;
    position: relative;
    width: 100%;
    padding: 5% 4%;
    box-sizing: border-box;

}

.page-preview .header {
    align-items: center;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.page-preview .logo {
    position: relative;
    height: 23px;
    width: auto;
}

.page-preview .text-wrapper {
    color: var(--collor-grey-700);
    font-family: "Space Grotesk", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
}

.page-preview .frame-rodape {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.page-preview .div {
    color: var(--collor-grey-700);
    font-family: "Space Grotesk", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.page-preview .list-photos-preview {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 2%;
    width: 100%;
    height: 95%;
    margin-top: 8px;
}

.page-preview .list-photos-preview-landscape {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    height: 95%;
    margin-top: 8px;
}