
.about {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    /* padding: 70px 0 0 0; */
}

.about .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    padding: 0 20px;
}

.about .about-title {
    font-family: "Space Grotesk", Helvetica;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 52.8px;
    color: #000000;
    margin: 20px 0;
    text-align: center;
    width: 100%;
}

.about .about-description {
    font-family: "Space Grotesk", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    color: #000000;
    margin: 8px 0;
    text-align: center;
    width: 100%;
}
