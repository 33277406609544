.login {
    background-color: var(--collor-brand-500);
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.login .div {
    background-color: var(--collor-grey-50);
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
}

.login .div-2 {
    display: flex;
    flex-direction: column;    
    justify-content: center;
    align-items: flex-start;
    padding: 0px 128px;
    width: 100%;
    height: 100%;
    gap: 24px;
    max-width: 720px;
}

.login .group {
    height: 40px;
    position: relative;
    width: 40.14px;
}

.login .title {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.login .text-wrapper {
    color: var(--collor-grey-800);
    font-family: "Space Grotesk", Helvetica;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.login .p {
    align-self: stretch;
    color: var(--collor-grey-600);
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
}

.login .form {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.login .input {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--collor-grey-200);
    border: 1px solid;
    border-color: var(--collor-grey-400);
    border-radius: 16px;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 16px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    outline: none;
}

.login .input:focus {  
    background-color: var(--collor-grey-300);
    border-color: var(--collor-grey-700);    
}

.login .text-wrapper-2 {
    color: var(--collor-grey-600);
    flex: 1;
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
}

.login .action-login {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
}

.login .frame {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
}

.login .rectangle {
    border: 1px solid;
    border-color: var(--collor-grey-400);
    border-radius: 4px;
    height: 16px;
    position: relative;
    width: 16px;
}

.login .text-wrapper-3 {
    color: var(--collor-grey-600);
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.login .text-wrapper-4 {
    color: var(--collor-brand-500);
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
    cursor: pointer;
}

.login .div-wrapper {
    align-items: center;
    align-self: stretch;
    background-color: var(--collor-brand-500);
    border: 1px solid;
    border-color: var(--collor-grey-400);
    border-radius: 16px;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 16px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
}

.login .text-wrapper-5 {
    color: var(--collor-grey-50);
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.login .cadastre-se {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.login .btn-registrar {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.login .arrow-right {
    height: 16px;
    position: relative;
    width: 16px;
}

.login .detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    width: 50%;
    padding: 32px;
    box-sizing: border-box;
}

.login .text-wrapper-6 {
    color: #ffffff;
    font-family: "Space Grotesk", Helvetica;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 100%;
    max-width: 442px;
}

.login .img {
    height: 462.36px;
    position: relative;
    width: 100%;
    max-width: 464px;
    height: auto;
}

.login .error-message {
    color: #ff0000;
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    
}

@media screen and (max-width: 1280px) {
    .login .div-2 {
        padding: 0px 32px;
    }
}

@media screen and (max-width: 1024px) {
    .login .detail {
        display: none;
    }

    .login .div {
        width: 100%;
    }

    .login .div-2 {
        width: 100%;
    }
}