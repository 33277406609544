.modal-pdfdetails-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-pdfdetails{
    width: 492px;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.modal-pdfdetails-title{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: var(--collor-brand-800);
}

.modal-pdfdetails-text{
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
}

.modal-pdfdetails-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;  
    margin-top: 32px;  
}

.modal-pdfdetails-button{
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    background-color: var(--collor-brand-500);
    color: #fff;
}

.modal-pdfdetails .orientacao-folha-3{
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 16px 0px;
    position: relative;
    width: 100%;
}

.modal-pdfdetails .orientacao-folha-3 img{
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(1.0);
}

.modal-pdfdetails .orientacao-folha-3 .title-2{
    cursor: pointer;
}

.modal-pdfdetails .orientacao-folha-3 .title-3{
    cursor: pointer;
    color: var(--collor-brand-500);
}

.modal-pdfdetails .orientacao-folha-3 .click{
    transform: scale(1.2);
}

.modal-pdfdetails .orientacao-folha-3 .downloadButton{    
    background-color: var(--collor-brand-800);
    border: none;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    gap: 16px;
}

.modal-pdfdetails .copyContainer .copyText{    
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--collor-brand-500);    
}

.modal-pdfdetails .copyContainer{    
    background: var(--collor-brand-800);
    padding: 8px 16px;
    box-sizing: border-box;
    border-radius: 8px;  
}