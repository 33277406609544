.title-cat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px none;
    display: flex;
    gap: 4px;
    position: relative;
    width: 100%;
    max-width: 704px;
    height: 40px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    box-sizing: border-box;
    transition: all 0.2s;
    padding-left: 0px;
}
.title-cat:hover {
    cursor: grab;
    background: var(--collor-grey-300);
    border-radius: 8px;
    padding-left: 8px;
}

.title-cat .text-wrapper {
    color: var(--collor-grey-700);
    font-family: "Space Grotesk", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    align-self: auto;
}

.title-cat .dots-six-vertical {
    height: 24px;
    left: -32px;
    position: absolute;
    top: 8px;
    width: 24px;
    cursor: pointer;
}

.title-cat .trash-icon{
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 8px;
    display: none;
}

.title-cat:hover .trash-icon{
    display: block;
}

@media screen and (max-width: 768px) {
    .title-cat .dots-six-vertical {
        display: none;
    }
}