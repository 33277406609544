.Account{
    width: 100%;
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.Account .createNewContainer{
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 1200px;
}

.Account .btNew{
    background: #0097b2;
    color: #fff;
    padding: 16px 32px;
    font-size: 24px;
    border-radius: 4px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    transition: all 0.2s;
    opacity: 1;
}

.Account .newName{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    transition: all 0.2s;
    opacity: 0;
}

.Account .newName form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    gap: 12px;
    box-sizing: border-box;
   
}

.Account input{
    width: 100%;
    color: #7e7e7e;
    background: rgb(219,219,219);
    padding: 16px 32px;
    border-radius: 4px;
    border: none;
    font-size: 18px; 
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box; 
    box-sizing: border-box; 
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Account button{
    background: #0097b2;
    color: #fff;
    padding: 16px 32px;
    font-size: 24px;
    border-radius: 4px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none;
}

.Account .cancelBT{
    font-size: 12px;
    cursor: pointer;
    margin-top: 16px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Account .historyContainer{
    margin-top: 32px;
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Account .historyContainer .title{
    font-size: 24px;
    margin-bottom: 32px;
}


.account {
    background-color: #f1f3f5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    min-height: calc(100vh - var(--main-menu-height));
  }
  
  .account .div-2 {
    background-color: var(--collor-grey-50);
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .account .section {
    display: flex;
    margin-top: 64px;
    width: 704px;
  }
  
  .account .content {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    
    position: relative;
  }
  
  .account .title-btn {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
    width: 100%;
  }
  
  .account .text-wrapper-2 {
    color: #000000;
    font-family: "Space Grotesk-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .account .rectangle {
    background-color: var(--collor-grey-500);
    flex: 1;
    flex-grow: 1;
    height: 1px;
    position: relative;
  }
  
  .account .btn {
    align-items: center;
    background-color: var(--collor-brand-500);
    border-radius: 40px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 8px 16px;
    position: relative;
    cursor: pointer;
  }
  
  .account .plus {
    height: 20px;
    position: relative;
    width: 20px;
  }
  
  .account .text-wrapper-3 {
    color: var(--collor-grey-50);
    font-family: "Space Grotesk-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .account .banner-cta {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--collor-brand-800);
    border-radius: 16px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    padding: 32px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }
  
  .account .icon-logo {
    height: 80px;
    left: 592px;
    position: absolute;
    top: 32px;
    width: 80px;
  }
  
  .account .title {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  
  .account .p {
    color: var(--collor-brand-200);
    font-family: "Space Grotesk-Medium", Helvetica;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 442px;
  }
  
  .account .text-wrapper-4 {
    align-self: stretch;
    color: var(--collor-brand-600);
    font-family: "Space Grotesk-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }
  
  .account .div-wrapper {
    align-items: center;
    background-color: var(--collor-brand-500);
    border-radius: 8px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 8px 16px;
    position: relative;
    cursor: pointer;
  }
  
  .account .text-wrapper-5 {
    color: var(--collor-brand-900);
    font-family: "Space Grotesk-Medium", Helvetica;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .account .last-reports {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 100%;
  }
  
  .account .title-viewall {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .account .text-wrapper-6 {
    color: #000000;
    font-family: "Space Grotesk-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .account .text-wrapper-7 {
    color: var(--collor-brand-500);
    font-family: "Space Grotesk-Bold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
    cursor: pointer;
  }
  
  .account .list-reports {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    flex-wrap: wrap;
    gap: 16px 16px;
    position: relative;
    width: 100%;
  }
  
  .account .card-report-instance {
    background-color: var(--collor-grey-50) !important;
  }
  
  .account .header {
    align-items: center;
    background-color: var(--collor-brand-900);
    display: flex;
    height: 80px;
    justify-content: space-between;
    left: 0;
    mix-blend-mode: normal;
    padding: 0px 32px;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .account .logo {
    flex: 1;
    flex-grow: 1;
    position: relative;
  }
  
  .account .navbar {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 32px;
    position: relative;
  }
  
  .account .text-wrapper-8 {
    color: var(--collor-grey-600);
    font-family: "Inter-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .account .perfil {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 12px;
    justify-content: flex-end;
    position: relative;
  }
  
  .account .text-wrapper-9 {
    color: var(--collor-grey-600);
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .account .ellipse {
    height: 32px;
    object-fit: cover;
    position: relative;
    width: 32px;
  }
  

  @media screen and (max-width: 768px) {
    .account .section {
      width: 100%;
      box-sizing: border-box;
      padding: 0 16px;
    }

    .account .icon-logo {
      display: none;
    }

    .account .p {
      width: 100%;
    }

    .account .list-reports {
      justify-content: space-between;
    }

    .account .card-report {
      width: 48%;
      max-width: 48%;
    }
  }

  @media screen and (max-width: 425px) {
    .account .card-report {
      width: 100% !important;
      max-width: 100% !important;
    }
  }