
.tutorial {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    /* padding: 70px 0 0 0; */
}

.tutorial .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    padding: 0 20px;
}

.tutorial .tutorial-title {
    font-family: "Space Grotesk", Helvetica;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 52.8px;
    color: #000000;
    margin: 20px 0;
    text-align: center;
    width: 100%;
}

.tutorial .tutorial-description {
    font-family: "Space Grotesk", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    color: #000000;
    margin: 8px 0;
    text-align: center;
    width: 100%;
}

.tutorial .videoPlayerContainer {
    width: 100%;
    max-width: 800px;
}

.tutorial .videoPlayer {
    
    height: 0;
	overflow: hidden; 
    padding-bottom: 56.25%;
    position: relative;
    margin: 20px 0;    
}

.tutorial .videoPlayer iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
