.backdropEditImageModal {
    display: none;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000e6;
    overflow-y: hidden;
    overscroll-behavior: contain;
}

.editImageModal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
}

.editImageModal .content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 704px;
    gap: 16px;
}

.editImageModal .sideButtonsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
}

.editImageModal .sideButtons{
    width: 36px;
    height: 36px;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 50%;
    background: #FFFFFF;
    cursor: pointer;
    margin-bottom: 132px;
}

.editImageModal .leftSide {
    transform: rotate(180deg);
}

.editImageModal  .imageContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.editImageModal .imageElement{
    width: 600px;
    height: 600px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.editImageModal .imageElement img{
    width: 100%;
    border-radius: 16px;
}

.editImageModal .description{
    width: 600px;
}

.editImageModal .description textarea{
    width: 100%;
    color: #FFFFFF;
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    background-color: transparent;
    outline: none;
    border: none;
    resize: none;
    background: var(--collor-grey-800);
    border-radius: 4px;
    padding: 8px;
}

.editImageModal .buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.editImageModal .buttonContainer .edit{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border-radius: 16px;
    padding: 16px 32px;
    box-sizing: border-box;
    background: var(--brand-500, #55C7CC);
    color: #FFF;
    font-family: "Space Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.editImageModal .buttonContainer .delete{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    padding: 16px;
    box-sizing: border-box;
    background: var(--grey-200, #E9ECEF);
    cursor: pointer;
}

.editImageModal .buttonContainer .cancel{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: #FFF;
    font-family: "Space Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.FIE_root {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 200;
    font-family: 'Space Grotesk' !important;
}

.FIE_topbar-save-button{
    font-weight: bold;
    font-size: 28px;
    padding: 16px 32px !important;
}

.FIE_main-container{
    flex-direction: column-reverse;
    align-items: center !important;
    gap: 16px !important;
}

.FIE_tabs{
    display: flex !important;
    flex-direction: row !important;
    overflow: hidden !important;
}

.FIE_warmth-tool-button{
    display: none !important;
}

.FIE_image-tool-button{
    display: none !important;
}


.FIE_topbar-center-options label:nth-child(1){
    display: none !important;
}

.FIE_topbar-center-options button:nth-child(2){
    display: none !important;
}

.FIE_topbar-center-options div:nth-child(3){
    display: none !important;
}

.FIE_topbar-save-button{
    background-color: #55c7cc !important;
    border-radius: 4px !important;
}

.FIE_topbar-save-button .SfxButton-Label{
    font-weight: bold !important;
    font-size: 20px !important;
    font-family: 'Space Grotesk' !important;
}

.FIE_topbar-buttons-wrapper{
    width: 244px !important;
    display: flex !important;
    justify-content: flex-start !important;
}

.FIE_topbar-center-options{
    width: 244px !important;
    display: flex !important;
    justify-content: center !important;
}

.FIE_topbar-close-button{
    width: 244px !important;
    display: flex !important;
    justify-content: flex-end !important;
}