body {
  margin: 0;
  font-family: 'Space Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box; 
  -moz-box-sizing: border-box; 
  box-sizing: border-box;
  overflow-x: hidden;
}

body.modal-open {
  overflow: hidden;
}

code {
  font-family: 'Space Grotesk', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

:root {
  /* Mode 1 */
  /* colors */
  --collor-brand-50: #DDF4F5;
  --collor-brand-200: #bbe9eb;
  --collor-brand-300: #99DDE0;  
  --collor-brand-500: #55c7cc;
  --collor-brand-600: #449fa3;
  --collor-brand-800: #225052;
  --collor-brand-900: #112829;
  --collor-grey-200: #e9ecef;
  --collor-grey-300: #dee2e6;
  --collor-grey-400: #ced4da;
  --collor-grey-500: #adb5bd;
  --collor-grey-50: #f1f3f5;
  --collor-grey-600: #868e96;
  --collor-grey-700: #495057;
  --collor-grey-800: #343a40;
  --collor-grey-900: #212529;
  --collor-valid: #1edd00;

  --main-menu-height: 70px;
  --main-menu-mobile-height: 50px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 20px var(--collor-grey-200); 
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 20px var(--collor-grey-500); 
}

p{
  margin: 0;
}

a{
  text-decoration: none;
  color: inherit;
}

.GlobalFlexRowCenter{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.GlobalFlexColumnCenter{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

