.MainMenu{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    height: var(--main-menu-height);
    /* position: fixed;
    top: 0;
    left: 0; */
    /* background: var(--collor-brand-900); */
    background: #FFFFFF;
    color: var(--collor-grey-600);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 10;
    transition: all 0.1s ease-in-out;
    flex-wrap: wrap;
}

.MainMenu .logoContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}


.MainMenu .logoContainer img{
    margin: 0 32px;
}

.MainMenu .buttonsContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    height: 100%;
}

.MainMenu .buttonsContainer p{
    cursor: pointer;
}

.MainMenu .loginContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    color: var(--collor-grey-600);
    height: 100%;
}

.MainMenu .loginContainer .btRegister{
    background: var(--collor-brand-500);
    color: #fff;
    padding: 16px 16px;
    border-radius: 16px;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
}

.MainMenu .loginContainer .btLogin{
    color: var(--collor-brand-500);
    padding: 8px 16px;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
}

.MainMenu .loginContainer .username{
    color: var(--collor-grey-600);
    padding: 8px;
    font-weight: 500;
    cursor: pointer;
    font-size: 16px;
}

.MainMenu .loginContainer .usericon{
    width: 32px;
    cursor: pointer;
}

.MainMenu .mobileButtonsContainer{
    display: none;
    align-items: center;
    cursor: pointer;
    height: var(--main-menu-mobile-height);
}

.MainMenu .buttonsMobileContainer{
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
    transition: all 0.1s ease-in-out;
    padding: 0 32px;
}

.MainMenu .loginMobileContainer{
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
    transition: all 0.1s ease-in-out;
    padding: 0 32px;
}

.MainMenu .loginMobileContainer .btRegister {
    background: var(--collor-brand-600);
    color: #fff;
    padding: 16px 16px;
    border-radius: 16px;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
}

@media screen and (max-width: 980px){
    .MainMenu{
        justify-content: space-between;
        height: var(--main-menu-mobile-height);
        position: fixed;
        top: 0;
        left: 0; 
    }

    .MainMenu .logoContainer{
        padding: 0 32px;
        height: var(--main-menu-mobile-height);
    }

    .MainMenu .logoContainer img{
        margin: 0;
        width: 150px;
    }

    .MainMenu .buttonsContainer{
        display: none;
    }

    .MainMenu .mobileButtonsContainer{
        display: flex;
        padding: 0 32px;
    }

    .MainMenu .loginContainer{
        display: none;
    }

    .MainMenu .buttonsMobileContainer{
        display: flex;
    }

    .MainMenu .loginMobileContainer{
        display: flex;

    }
}
  