.photo-info {    
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0px 8px;
    position: relative;
    width: 100%;
    max-width: 688px;
    /* transition: all 0.3s; */
    background-color: transparent;
    /* transform: translateX(0px); */
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    cursor: grab;
  }

  .photo-info:hover {
    background-color: var(--collor-grey-300);
    border-radius: 8px;
    /* transform: translateX(-1px); */
  }
  
  .photo-info .image-index {
    color: var(--collor-grey-600);
    font-family: "Space Grotesk", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 24px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .photo-info .rectangle {
    background-color: transparent;
    border-radius: 4px;
    height: 50px;
    position: relative;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .photo-info .div {
    align-self: stretch;
    color: var(--collor-grey-600);
    font-family: "Space Grotesk", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 15.6px;
    margin-top: -1px;
    position: relative;
    width: 576px;
  }
  
  .photo-info .frame {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 4px;
    justify-content: center;
    position: relative;
  }
  
  .photo-info .p {
    align-self: stretch;
    color: var(--collor-grey-600);
    font-family: "Space Grotesk", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 15.6px;
    margin-top: -1px;
    position: relative;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .photo-info .dots-three-vertical {
    height: 24px;
    position: relative;
    width: 24px;
    cursor: pointer;
  }
  
  .photo-info .dots-six-vertical {
    height: 24px;
    left: -16px;
    position: absolute;
    top: 8px;
    width: 16px;
    cursor: pointer;
    display: none;
  }
  

  .photo-info:hover .dots-six-vertical {
    display: block;
  }

  .backdropSummaryImage{
    display: none;
    z-index: 11;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000000;
  }
 
  .photo-info .modal-actions {
    align-items: flex-start;
    background-color: var(--collor-grey-50);
    border: 1px solid;
    border-color: var(--collor-grey-300);
    border-radius: 8px;
    box-shadow: 0px 4px 32px #00000040;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 160px;
    display: none;
    cursor: pointer;
    z-index: 12;
  }
  
  .photo-info .modal-actions .item-link-perfil {
    align-items: center;
    align-self: stretch;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--collor-grey-400);
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 8px 12px;
    position: relative;
    width: 100%;
  }
  
  .photo-info .modal-actions .img {
    height: 20px;
    position: relative;
    width: 20px;
  }
  
  .photo-info .modal-actions .sair {
    color: var(--collor-grey-700);
    font-family: "Space Grotesk", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .photo-info .modal-actions .div {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 8px 12px;
    position: relative;
    width: 100%;
  }
  
  .photo-info .modal-actions .text-wrapper {
    color: #de0000;
    font-family: "Space Grotesk", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }

  .photo-info .trashIcon{
    display: none;
    width: 20px;
    height: 20px;
    margin: 0 8px;
    cursor: pointer;
  }

  .photo-info:hover .trashIcon{
    display: block;
  }

  
  
  @media screen and (max-width: 768px) {
    .photo-info {
      background-color: var(--collor-grey-300);
      border-radius: 8px;
      box-sizing: border-box;
    }
  }