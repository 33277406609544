.card-report {
    align-items: flex-start;
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 12px 16px #0000000a;
    display: flex;
    flex-direction: column;
    max-width: 200px;
    overflow: hidden;
    position: relative;
    width: 164px;
    cursor: pointer;
  }
  
  .card-report .detail {
    align-self: stretch;
    background-color: var(--collor-brand-500);
    height: 48px;
    position: relative;
    width: 100%;
  }
  
  .card-report .title-desc {
    align-items: flex-start;
    align-self: stretch;
    background-color: #ffffff;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    padding: 16px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }
  
  .card-report .text-wrapper {
    align-self: stretch;
    color: var(--collor-grey-800);
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 400;
    height: 54px;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
  }
  
  .card-report .div {
    align-self: stretch;
    color: var(--collor-grey-600);
    font-family: "Space Grotesk", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }
  
  .card-report .file-text {
    height: 24px;
    left: 12px;
    position: absolute;
    top: 36px;
    width: 24px;
  }
  

  .card-report .trash-icon{
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  