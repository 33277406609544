.registre-se {
    background-color: var(--collor-brand-500);
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.registre-se .container {
    background-color: var(--collor-grey-50);
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
}

.registre-se .div-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 128px;
    width: 100%;
    height: 100%;
    gap: 24px;
    max-width: 720px;
}

.registre-se .detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    width: 50%;
    padding: 32px;
    box-sizing: border-box;
}

.registre-se .detail .text {
    color: #ffffff;
    font-family: "Space Grotesk", Helvetica;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 100%;
    max-width: 442px;
}

.registre-se .img {
    height: 462.36px;
    position: relative;
    width: 100%;
    max-width: 464px;
    height: auto;
}

.registre-se .group {
    height: 40px;
    position: relative;
    width: 40.14px;
}

.registre-se .title {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.registre-se .text-wrapper {
    color: var(--collor-grey-800);
    font-family: "Space Grotesk", Helvetica;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.registre-se .div {
    align-self: stretch;
    color: var(--collor-grey-600);
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
}

.registre-se .form {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.registre-se .input {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--collor-grey-200);
    border: 1px solid;
    border-color: var(--collor-grey-400);
    border-radius: 16px;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 16px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    outline: none;
}

.registre-se .input:focus {  
    background-color: var(--collor-grey-300);
    border-color: var(--collor-grey-700);    
}

.registre-se .text-wrapper-2 {
    color: var(--collor-grey-600);
    flex: 1;
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
}

.registre-se .info-password {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 32px;
    padding: 0px 16px;
    position: relative;
}

.registre-se .col {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    position: relative;
}

.registre-se .item-pass {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
}


.registre-se .text-wrapper-3 {
    color: var(--collor-grey-600);
    font-family: "Space Grotesk", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.registre-se .ellipse {
    background-color: #d9d9d9;
    border-radius: 4px;
    height: 8px;
    position: relative;
    width: 8px;
}

.registre-se .ellipse-valid {
    background-color: var(--collor-valid);
}

.registre-se .div-wrapper {
    align-items: center;
    align-self: stretch;
    background-color: var(--collor-brand-500);
    border: 1px solid;
    border-color: var(--collor-grey-400);
    border-radius: 16px;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 16px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
}

.registre-se .text-wrapper-4 {
    color: var(--collor-grey-50);
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.registre-se .cadastre-se {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
}

.registre-se .text-wrapper-5 {
    color: var(--collor-grey-600);
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.registre-se .termos-politicas {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    position: relative;
}

.registre-se .text-wrapper-6 {
    color: var(--collor-brand-500);
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
    cursor: pointer;
}

.registre-se .cadastre-se-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    position: relative;
    width: 100%;
}

@media screen and (max-width: 1280px) {
    .registre-se .div-2 {
        padding: 0px 32px;
    }
}


@media screen and (max-width: 1024px) {
    .registre-se .container {
        width: 100%;
    }
    .registre-se .detail {
        display: none;
    }
    .registre-se .div-2 {
        padding: 0px 32px;
    }

    .registre-se .info-password {
        gap: 8px;
        padding: 0px 4px;
    }
}

@media screen and (max-width: 768px) {
    .registre-se .div-2 {
        padding: 0px 16px;
    }
}