.switch {
  background-color: var(--collor-grey-300);
  border-radius: 16px;
  height: 24px;
  width: 48px;
  transition: all 0.2s; 
}

.switch-enabled {
  background-color: var(--collor-brand-500);
}

.switch .thumb-disable {
  position: relative;
  top: 2px;
  left: 2px;
  border-radius: 10px;
  height: 20px;
  width: 20px;
  background: var(--collor-grey-300);
  cursor: pointer;
  transition: all 0.2s; 
}

.switch .thumb-enabled {  
  background-color: var(--collor-brand-500);
}

.switch .track-disable {
    border-radius: 16px;
    position: absolute;
    left: 8px;
    height: 12px;
    top: 4px;
    width: 32px;
    background-color: var(--collor-grey-300);  
    transition: all 0.2s;  
}

.switch .track-enabled {  
  background-color: var(--collor-brand-500);    
}

.switch .div-disable {
   position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    height: 20px;
    width: 20px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 8px #00000029;  
    transition: all 0.2s;  
}

.switch .div-enabled {
  left: 22px;        
}
