.ImageOrderCard{
    width: 180px;
    height: 226x;    
    box-sizing: border-box;
}

.ImageOrderCard .ImageContainer{
    width: 180px;
    height: 180px;
    background: rgb(219,219,219);
    display:  flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ImageOrderCard .ImageContainer img{
    width: 176px;
    height: auto;
}

.ImageOrderCard .DeleteContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 8px;
    background: rgb(219,219,219);
}

.ImageOrderCard .DeleteIcon{
    width: 20px;
    cursor: pointer;
}