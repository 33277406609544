.SummaryPage {
    border: 1px none;
    display: flex;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 704px;
    height: 30px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    box-sizing: border-box;
}

.SummaryPage .text-wrapper {
    color: var(--collor-grey-700);
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    align-self: auto;
}

.SummaryPage .rectangle {
    border: 1px dashed;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-color: var(--collor-grey-400);
    flex: 1;
    flex-grow: 1;
    height: 2px;
    position: relative;
    margin-top: 12px;
    display: none;
}

.SummaryPage .div {
    color: var(--collor-grey-600);
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: right;
    width: fit-content;
    align-self: auto;
    display: none;
}