.ImageUpload{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 70px);
    box-sizing: border-box;
    padding-top: 48px;
    gap: 48px;
}

.ImageUpload input[type="file"]{
    display: none;
}

.ImageUpload button{
    background: #0097b2;
    color: #fff;
    padding: 16px 32px;
    font-size: 24px;
    border-radius: 4px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none; 
}

.ImageUpload .ImagesContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ImageUpload .ImagesDroppable{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
}

.ImageUpload .btNextContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}