.modal-confirm-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-confirm{
    width: 400px;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.modal-confirm-title{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.modal-confirm-text{
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
}

.modal-confirm-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;    
}

.modal-confirm-button{
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    background-color: var(--collor-brand-500);
    color: #fff;
}