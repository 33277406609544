.btn {
  all: unset;
  align-items: center;
  background-color: var(--collor-brand-500);
  border-radius: 16px;
  box-sizing: border-box;
  display: inline-flex;
  gap: 8px;
  padding: 16px 20px;
  position: relative;
  cursor: pointer;
}

.btn .experimente-gr-tis {
  color: #ffffff;
  font-family: "Space Grotesk", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}
