.SheetConfig {
  width: 100%;
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
  /* padding-top: 96px; */
  box-sizing: border-box;
}

.SheetConfig .pageOrientation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
}

.SheetConfig .pageOrientation label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 300px;
  gap: 12px;
}

.SheetConfig .pageOrientationRadio {
  accent-color: #000000;
  width: 30px;
  height: 30px;
}

.SheetConfig .pageNumber {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.SheetConfig .pageNumber .title {
  font-size: 18px;
  font-weight: 300;
}

.SheetConfig .pageNumberSheet {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 400px;
  height: 250px;
  border: 2px solid #000000;
}

.SheetConfig .pageNumberRadio {
  accent-color: #000000;
  width: 16px;
  height: 16px;
}

.SheetConfig .pageNumberSheet .top_left {
  position: absolute;
  top: 8px;
  left: 8px;
}

.SheetConfig .pageNumberSheet .top_middle {
  position: absolute;
  top: 8px;
  left: calc(50% - 8px);
}

.SheetConfig .pageNumberSheet .top_right {
  position: absolute;
  top: 8px;
  right: 8px;
}

.SheetConfig .pageNumberSheet .bottom_left {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.SheetConfig .pageNumberSheet .bottom_middle {
  position: absolute;
  bottom: 8px;
  left: calc(50% - 8px);
}

.SheetConfig .pageNumberSheet .bottom_right {
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.SheetConfig .ButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.SheetConfig .nextBT {
  background: #0097b2;
  color: #fff;
  padding: 16px 32px;
  font-size: 24px;
  border-radius: 4px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
}

.SheetConfig .cancelBT {
  font-size: 12px;
  cursor: pointer;
}




.formName {
  align-items: center;
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 704px;
  box-sizing: border-box;
  padding: 54px 0;
}

.formName .title {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;
}

.formName .text-wrapper {
  align-self: stretch;
  color: var(--collor-grey-900);
  font-family: "Space Grotesk", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.formName .div {
  align-self: stretch;
  color: var(--collor-grey-600);
  font-family: "Space Grotesk", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}


.formName .input {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--collor-grey-200);
  border: 1px solid;
  border-color: var(--collor-grey-400);
  border-radius: 16px;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 16px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  outline: none;
}

.formName .input:focus {
  background-color: var(--collor-grey-300);
  border-color: var(--collor-grey-700);
}

.formName .input-counter {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  margin-top: -56px;
  margin-right: 16px;
  gap: 8px;
}

.formName .text-wrapper-2 {
  color: var(--collor-grey-600);
  flex: 1;
  font-family: "Space Grotesk", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.formName .text-wrapper-3 {
  color: var(--collor-grey-600);
  flex: 1;
  font-family: "Space Grotesk", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: right;
}

.formName .frame {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: flex-end;
  position: relative;
  flex-direction: column-reverse;
}

.formName .div-wrapper {
  align-items: center;
  border-radius: 16px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 8px 8px 16px 8px;
  position: relative;
  cursor: pointer;
}

.formName .text-wrapper-4 {
  color: var(--collor-brand-500);
  font-family: "Space Grotesk", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.formName .input-2 {
  align-items: center;
  background-color: var(--collor-brand-500);
  border-radius: 16px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 16px 32px;
  position: relative;
  outline: none;
  border: none;
  cursor: pointer;
}

.formName .text-wrapper-5 {
  color: var(--collor-grey-50);
  font-family: "Space Grotesk", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.formName .arrow-right {
  height: 20px;
  position: relative;
  width: 20px;
}

.formName .addnew {
  align-items: center;
  border: 1px none;
  display: inline-flex;
  justify-content: center;
  min-height: 36px;
  min-width: 177px;
  position: relative;
  width: 100%;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.formName .addnew-text-wrapper {
  color: var(--collor-brand-500);
  font-family: "Space Grotesk", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
  cursor: pointer;
  padding-right: 8px;
}

.formName .addnew-plus {
  height: 20px;
  position: relative;
  width: 20px;
  cursor: pointer;

}

.formName .skipContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-self: flex-end;
  gap: 8px;
}

.formName .skipText {
  color: var(--collor-brand-500);
  font-family: "Space Grotesk", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
}

.formName .removeAllPictires {
  color: var(--collor-brand-500);
  font-family: "Space Grotesk", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  cursor: pointer;
}

.SheetConfig .bottom-next {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  position: relative;
}

.SheetConfig .bottom-next .photoPerPageSelect {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  flex-wrap: wrap;
  width: 155px;
  position: absolute;
  bottom: 142px;
  right: 16px;
}

.SheetConfig .bottom-next .photoPerPageSelectContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
  flex-wrap: wrap;
}

.SheetConfig .bottom-next .photoPerPageSelectTitle {
  color: var(--grey-600, #868E96);
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.criar-relatorio {
  background-color: #f1f3f5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding-top: 12px;
}

.criar-relatorio .div-2 {
  background-color: var(--collor-grey-50);
  /* height: 932px; */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.criar-relatorio .section {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* height: 884px;     */
  box-sizing: border-box;
}

.criar-relatorio .menu-sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 40px 32px;
  position: relative;
  width: 428px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.criar-relatorio .text-wrapper-3 {
  align-self: stretch;
  color: var(--collor-grey-700);
  font-family: "Space Grotesk", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.criar-relatorio .orientacao-folha {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--collor-grey-300);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 16px 0px;
  position: relative;
  width: 100%;
}

.criar-relatorio .orientacao-folha-icon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.criar-relatorio .div-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.criar-relatorio .text-wrapper-4 {
  color: var(--collor-grey-600);
  font-family: "Space Grotesk", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.criar-relatorio .name {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.criar-relatorio .p {
  color: var(--collor-grey-700);
  flex: 1;
  font-family: "Space Grotesk", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.criar-relatorio .img {
  height: 32px;
  position: relative;
  width: 32px;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
}

.criar-relatorio .img_clicked {
  transform: scale(1.1);
}



.criar-relatorio .options {
  flex: 0 0 auto;
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.criar-relatorio .orientacao-folha-2 {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--collor-grey-300);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0px;
  position: relative;
  width: 100%;
}

.criar-relatorio .options-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
  width: 100%;
}

.criar-relatorio .text-wrapper-5 {
  color: var(--collor-grey-700);
  flex: 1;
  font-family: "Space Grotesk", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 324px;
}

.criar-relatorio .input {
  align-items: center;
  background-color: var(--collor-brand-500);
  border-radius: 16px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 16px 32px;
  position: relative;
  cursor: pointer;
}

.criar-relatorio .text-wrapper-6 {
  color: var(--collor-grey-50);
  font-family: "Space Grotesk", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.criar-relatorio .img-2 {
  height: 20px;
  position: relative;
  width: 20px;
}

.criar-relatorio .text-wrapper-7 {
  color: var(--collor-grey-600);
  font-family: "Space Grotesk", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.criar-relatorio .orientacao-folha-3 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--collor-grey-300);
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 16px 0px;
  position: relative;
  width: 100%;
}

.criar-relatorio .orientacao-folha-3 p {
  color: var(--collor-brand-500);
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.criar-relatorio .title-2 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  position: relative;
  width: 100%;
  justify-content: flex-end;
}

.criar-relatorio .switch-instance {
  position: relative !important;
}

.criar-relatorio .orientacao-folha-4 {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--collor-grey-300);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 16px 0px;
  position: relative;
  width: 100%;
}

.criar-relatorio .orientacao-folha-5 {
  align-items: flex-start;
  align-self: stretch;
  /* border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--collor-grey-300); */
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 16px 0px;
  position: relative;
  width: 100px;
  z-index: 2;
}

.criar-relatorio .orientacao-folha-5 p {
  color: var(--collor-brand-500);
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.criar-relatorio .frame {
  align-items: center;
  align-self: stretch;
  background-color: var(--collor-grey-200);
  border: 1px solid;
  border-color: var(--collor-grey-400);
  border-radius: 16px;
  display: flex;
  height: 50px;
  justify-content: space-between;
  overflow: hidden;
  padding: 0px 0px 0px 16px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.criar-relatorio .text-wrapper-8 {
  color: var(--collor-grey-600);
  font-family: "Space Grotesk", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.6px;
  line-height: normal;
  position: relative;
  width: fit-content;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 194px;
  overflow: hidden;
    text-overflow: ellipsis;
}

.criar-relatorio .frame-2 {
  align-items: center;
  background-color: var(--collor-brand-500);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 16px 24px;
  position: relative;
  cursor: pointer;
}

.criar-relatorio .text-wrapper-9 {
  color: var(--collor-grey-50);
  font-family: "Space Grotesk", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.7px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.criar-relatorio .input-rodape-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.criar-relatorio .frame-3 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 32px 0px;
  position: relative;
  width: 100%;
  margin-top: -78px;
  z-index: 1;
}

.criar-relatorio .cancel {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.criar-relatorio .text-wrapper-10 {
  color: var(--collor-grey-600);
  font-family: "Space Grotesk", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-decoration: underline;
  width: fit-content;
  cursor: pointer;
}

.criar-relatorio .preview-section {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
}

.criar-relatorio .preview-section-landscape {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  flex-direction: column;
}

.criar-relatorio .content {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 32px 16px;
  position: relative;
  /* height: 842px; */
  /* width: 563px; */
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  box-sizing: border-box;
}

.criar-relatorio .content-landscape {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  position: relative;
  /* height: 595px;
    width: 842px; */
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  box-sizing: border-box;
}

.criar-relatorio .relatorio {
  height: 842px;
  position: relative;
  width: 595px;
}

.criar-relatorio .relatorio-landscape {
  height: 842px;
  position: relative;
  width: 595px;
}

.criar-relatorio .col-pages {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  /* padding: 32px 0px; */
  position: relative;
  width: 170px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  box-sizing: border-box;
  margin: 32px 0 32px 16px;
  height: 842px;
}

.criar-relatorio .col-pages-landscape {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 16px 0px 0px 0px;
  margin: 0 32px 8px 32px;
  position: relative;
  width: 894px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  box-sizing: border-box;
  gap: 16px;
}

.criar-relatorio .page-item {
  align-items: flex-start;
  background-color: var(--collor-grey-50);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 16px 24px;
  position: relative;
}

.criar-relatorio .page-item-landscape {
  align-items: flex-start;
  background-color: var(--collor-grey-50);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 16px 0px;
  position: relative;
}

.criar-relatorio .page-item-2 {
  background-color: #ffffff;
  height: 159px;
  position: relative;
  width: 112.36px;
}

.criar-relatorio .header-2 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 97px;
}

.criar-relatorio .logo-2 {
  position: relative;
  width: 25px;
}

.criar-relatorio .text-wrapper-11 {
  color: var(--collor-grey-700);
  font-family: "Space Grotesk", Helvetica;
  font-size: 2.3px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.criar-relatorio .frame-4 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 8px;
  position: absolute;
  top: 151px;
  width: 97px;
}

.criar-relatorio .text-wrapper-12 {
  color: var(--collor-grey-700);
  font-family: "Space Grotesk", Helvetica;
  font-size: 2.3px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.19px;
  position: relative;
  width: fit-content;
}

.criar-relatorio .list-photos {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 2px 2px;
  left: 8px;
  position: absolute;
  top: 25px;
  width: 97px;
}

.criar-relatorio .rectangle {
  background-color: #d9d9d9;
  height: 54.25px;
  position: relative;
  width: 31px;
}

.criar-relatorio .text-wrapper-13 {
  color: var(--collor-grey-700);
  font-family: "Space Grotesk", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 10px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 17px;
  white-space: nowrap;
}

.criar-relatorio .element-2 {
  color: var(--collor-grey-700);
  font-family: "Space Grotesk", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 7px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 17px;
  white-space: nowrap;
}

.criar-relatorio .element-3 {
  color: var(--collor-grey-700);
  font-family: "Space Grotesk", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 8px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 17px;
  white-space: nowrap;
}

.criar-relatorio .frame-5 {
  height: 40px;
  left: 1303px;
  position: absolute;
  top: 880px;
  width: 40px;
}

.criar-relatorio .frame-6 {
  height: 40px;
  left: 1303px;
  position: absolute;
  top: 32px;
  width: 40px;
}

.criar-relatorio .bt-scroll-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  margin: 32px 0 0 0;
  box-sizing: border-box;
  height: 842px;
}

.criar-relatorio .bt-scroll-container-landscape {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 894px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  margin: 0px 32px;
  box-sizing: border-box;
}

.criar-relatorio .bt-scroll-base {
  align-items: center;
  background-color: var(--collor-brand-500);
  display: flex;
  gap: 8px;
  width: 40px;
  border-radius: 50%;
  height: 40px;
  justify-content: center;
  position: relative;
  cursor: pointer;
  margin-left: 8px;
}

.criar-relatorio .bt-scroll-base .up {
  height: 24px;
  position: relative;
  width: 24px;
}

.criar-relatorio .bt-scroll-base .left {
  height: 24px;
  position: relative;
  width: 24px;
  transform: rotate(270deg);
}

.criar-relatorio .bt-scroll-base .down {
  height: 24px;
  position: relative;
  width: 24px;
  transform: rotate(180deg);
}

.criar-relatorio .bt-scroll-base .right {
  height: 24px;
  position: relative;
  width: 24px;
  transform: rotate(90deg);
}

.criar-relatorio .scrollbar {
  background-color: #e9ecef;
  border-radius: 16px;
  height: 884px;
  left: 1280px;
  position: absolute;
  top: 32px;
  width: 8px;
}

.criar-relatorio .rectangle-2 {
  background-color: var(--collor-grey-500);
  border-radius: 16px;
  height: 772px;
  position: relative;
  top: 39px;
}

.criar-relatorio .header-instance {
  left: 0 !important;
  position: fixed !important;
  top: 0 !important;
}

.criar-relatorio .inputAddLogo {
  display: none;
}

.criar-relatorio .btRemoveLogo{
  position: absolute;
  top: 13px;
  right: 106px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 1280px) {}

@media screen and (max-width: 768px) {
  .formName {
    width: 100%;
    padding: 0 16px;
  }

  .SheetConfig .bottom-next {
    margin-top: 132px;
  }
}