.Home{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Home .offset{
    position: absolute;
    top: -70px;
    left: 0;
    width: 1px;
    height: 1px;
    background: transparent;
}

.Home .section_1{
    width: 100%;  
    height: calc(100vh - 70px);  
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items: center; 
    gap: 32px; 
    /* background: linear-gradient(90deg, rgba(219,219,219,1) 0%, rgba(255,255,255,1) 100%); */
    position: relative; 
}

.Home .section_1 .section1Container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.Home .section_1 .frame {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 72px;
    position: relative;
    width: 50%;
    align-self: center;
    box-sizing: border-box;
    padding: 0 0 0 10%;
  }
  
  .Home .section_1 .frame .div {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    position: relative;
  }
  
  .Home .section_1 .frame .p {
    color: var(--collor-brand-800);
    font-family: "Space Grotesk", Helvetica;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 52.8px;
    margin-top: -1px;
    position: relative;
    width: 552px;
  }
  
  .Home .section_1 .frame .text-wrapper-2 {
    color: var(--collor-brand-300);
    font-family: "Space Grotesk", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 33.6px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .Home .section_1 .frame .menu {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
  }
  
  .Home .section_1 .frame .btn-instance {
    flex: 0 0 auto !important;
  }
  
  .Home .section_1 .frame .text-wrapper-3 {
    color: var(--collor-grey-500);
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
    cursor: pointer;
  }

  .Home .section_1 .frame .btn {
    all: unset;
    align-items: center;
    background-color: var(--collor-brand-500);
    border-radius: 16px;
    box-sizing: border-box;
    display: inline-flex;
    gap: 8px;
    padding: 16px 20px;
    position: relative;
    cursor: pointer;
  }
  
  .Home .section_1 .rightSpacer{
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }

  .Home .section_1 .rightSpacer .img{
    width: 100%;    
  }

  .Home .section_1 .logoBG{
     position: absolute;
     top: 75%;
     left: 10%;
  }



  .Home .section_1 .frame .btn .text-wrapper {
    color: #ffffff;
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  
  

.Home .section_1 .leftContainer{
    width: 50%;
    max-width: 760px;
    gap: 32px;
}

.Home .section_1 .leftContainer img{
    width: 90%;
    max-width: 337px;
}

.Home .section_1 .rightContainer{
    width: 50%;
    max-width: 760px;
}

.Home .section_1 .rightContainer img{
    width: 90%;
    max-width: 449px;
}

.Home .section_2{
    width: 100%;
    max-width: 1600px;
    height: calc(100vh - 70px); 
    display: flex;
    flex-direction: row; 
    justify-content: space-evenly;
    align-items: center; 
    gap: 32px;
    text-align: center;
    font-weight: 600;
    position: relative; 
}

.Home .section_2 .card{
    width: 320px;
}

.Home .section_2 .cardTitle{
    font-size: 26px;
    font-weight: 700;
    margin: 16px 0 8px 0;
}

.Home .section_3{
    width: 100%;
    max-width: 1600px;
    height: calc(100vh - 70px); 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    position: relative; 
}

.Home .section_3 .title{
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    color: #0097b2;
    margin-bottom: 16px;
}

.Home .section_3 .price{
    text-align: center;
    font-size: 24px;
    font-weight: 600;
}

.Home .section_3 .pricesContainer{
    justify-content: space-evenly;
    width: 100%;
    margin: 48px 0 16px 0;
}

.Home .section_3 li{
    color: #7e7e7e;
    margin-bottom: 8px;
}

.Home .section_3 .button{
    width: 256px;
    background: #0097b2;
    color: #fff;
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}



.desktop {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    /* padding: 70px 0 0 0; */
  }
  
  .desktop .div-2 {
    background-color: #ffffff;
    position: relative;
    /* width: 1440px; */
    width: 100%;
  }
  
  .desktop .overlap {
    /* height: 1408px; */
    /* left: 53px;
    position: absolute;
    top: 88px; */
    /* width: 1387px; */
    width: 100%;
  }
  
  .desktop .overlap-group {
    /* height: 1408px; */
    /* left: 0;
    position: absolute;
    top: 0; */
    /* width: 1387px; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: calc(100vh - 70px);
  }

  .desktop .img-container{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .desktop .group {
    height: 514px;
    left: 0px;
    position: absolute;
    top: 338px;
    width: 516px;
    z-index: 0;
  }
  
  .desktop .criar-relatorio {
    height: 654px;
    /* left: 577px; */
    position: absolute;
    top: 99px;
    right: 0;
    width: 810px;
  }
  
  .desktop .img {
    height: 654px;
    /* left: 553px; */
    position: absolute;
    top: 52px;
    right: 0;
    width: 834px;
  }
  
  .desktop .criar-relatorio-2 {
    height: 654px;
    /* left: 524px; */
    position: absolute;
    top: 0;
    right: 0;
    width: 863px;
  }
  
  .desktop .frame {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 72px;
    width: 1176px;
    box-sizing: border-box;
    padding: 72px 0 0 0 ;
    /* left: 103px;
    position: absolute;
    top: 94px; */
  }
  
  .desktop .frame-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    position: relative;
  }
  
  .desktop .p {
    color: var(--collor-brand-800);
    font-family: "Space Grotesk", Helvetica;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 52.8px;
    margin-top: -1px;
    position: relative;
    width: 552px;
  }
  
  .desktop .text-wrapper-4 {
    color: var(--collor-brand-300);
    font-family: "Space Grotesk", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 33.6px;
    position: relative;
    width: fit-content;
  }
  
  .desktop .menu {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
  }
  
  .desktop .btn-2 {
    flex: 0 0 auto !important;
  }
  
  .desktop .text-wrapper-5 {
    color: var(--collor-grey-500);
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }

  .desktop .container-part2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 64px;
  }
  
  .desktop .pexels-anna {
    height: 438px;
    left: 0;
    object-fit: cover;
    /* position: absolute;
    top: 970px; */
    width: 655px;
    margin-left: -64px;
    z-index: 1;
  }
  
  .desktop .content-text {
    align-items: flex-start;
    /* display: inline-flex; */
    display: flex;
    flex-direction: column;
    gap: 40px;
    /* left: 711px;
    position: absolute;
    top: 1018px; */
  }
  
  .desktop .relat-rios-que {
    color: var(--collor-brand-800);
    font-family: "Space Grotesk", Helvetica;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 44.8px;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .desktop .span {
    color: #225052;
  }
  
  .desktop .text-wrapper-6 {
    color: #55c7cc;
    font-family: "Space Grotesk", Helvetica;
    font-weight: 700;
  }
  
  .desktop .text-wrapper-7 {
    color: var(--collor-grey-700);
    font-family: "Space Grotesk", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 33.6px;
    position: relative;
    width: 520px;
  }
  
  .desktop .overlap-2 {
    height: 815px;
    /* left: 156px;
    position: absolute;
    top: 1896px; */
    width: 100%;
    margin: 160px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .desktop .overlap-3 {
    /* height: 815px; */
    /* left: 0;
    position: absolute;
    top: 0; */
    /* width: 1128px; */
    position: relative
  }
  
  .desktop .group-2 {
    height: 514px;
    left: 0;
    position: absolute;
    top: 301px;
    width: 516px;
  }
  
  .desktop .solutions {
    /* align-items: flex-start; */
    display: flex;    
    /* left: 384px;
    position: absolute;
    top: 0; */
    width: 816px;
    margin-left: 84px;
  }
  
  .desktop .item-solution {    
    /* background-color: var(--collor-brand-50);
    border-radius: 24px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    /* padding: 32px; */
    position: relative;
    width: 360px;
    height: 294px;
    box-sizing: border-box;
    margin-top: 156px;
  }

  .desktop .item-solution .item-number{
    position: absolute;
    color: var(--collor-brand-200);
    font-size: 72px;
    font-weight: 800;
    top: -48px;
    font-family: "Space Grotesk", Helvetica;
  }

  .desktop .item-solution .item-line{
    width: 100%;
    height: 2px;
    background-color: var(--collor-brand-200);
    position: relative;
  }

  .desktop .item-solution .item-circle{
    width: 24px;
    height: 24px;
    background-color: #FFFFFF;
    border: 2px solid var(--collor-brand-200);
    border-radius: 50%;
    position: absolute;
    top: -12px;
    left: -12px;
    left: 360px;
  }

  .desktop .item-solution .item-buttoncontainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    position: relative;
    width: 100%;
  }

  .desktop .item-solution .item-button-change-item{    
    background-color: #fff;
    color: var(--collor-brand-800);    
    border: 2px solid var(--collor-brand-500);
    width: 24px;
    height: 24px;
    border-radius: 12px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 12px;
    font-weight: 500;
    opacity: 0.5;
  }

  .desktop .item-solution .item-button-change-selected{
    background-color: var(--collor-brand-500);
    color: #fff;
    opacity: 1;
  }

  .desktop .item-solution .item-img{    
    border-radius: 16px;
    position: absolute;
    overflow: hidden;
    top: -156px;
    left: 372px;
  }

  .desktop .item-solution .item-spacer{
    height: 100%;
  }
  
  /* .desktop .file-text {
    height: 32px;
    position: relative;
    width: 32px;
  } */
  
  .desktop .text-wrapper-8 {
    align-self: stretch;
    color: var(--collor-brand-800);
    font-family: "Space Grotesk", Helvetica;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    max-width: 240px;
  }
  
  .desktop .text-wrapper-9 {
    align-self: stretch;
    color: var(--collor-grey-700);
    font-family: "Space Grotesk", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    position: relative;
    max-width: 240px;
  }
  
  
  .desktop .title-solutions {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 32px;
    position: relative;
    /* left: 0;
    position: absolute;
    top: 0; */
  }
  
  .desktop .crie-conecte {
    color: var(--collor-brand-800);
    font-family: "Space Grotesk", Helvetica;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 67.2px;
    position: relative;
  }
  
  .desktop .overlap-4 {
    /* height: 811px; */
    /* left: 156px;
    position: absolute;
    top: 4272px; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    margin-top: 160px;
    padding-bottom: 160px;
    overflow: hidden;
    height: fit-content;
  }
  
  .desktop .overlap-group-2 {
    /* height: 811px; */
    /* left: 0;
    position: absolute;
    top: 0; */
    width: 100%;
    max-width: 700px;
  }
  
  .desktop .group-3 {
    height: auto;
    left: 0;
    position: absolute;
    top: 297px;
    width: 620px;
    z-index: 0;
  }
  
  .desktop .form-contact {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    padding: 8px;
    /* left: 480px;
    position: absolute;
    top: 0; */
    width: 648px;
  }
  
  .desktop .text-wrapper-10 {
    color: var(--collor-brand-800);
    font-family: "Space Grotesk", Helvetica;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 57.6px;
    margin-top: -1px;
    position: relative;
    width: 384px;
  }
  
  .desktop .form {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  
  .desktop .input {
    align-items: center;
    align-self: stretch;
    background-color: var(--collor-grey-200);
    border: 1px solid;
    border-color: var(--collor-grey-400);
    border-radius: 16px;
    display: flex;
    gap: 8px;
    /* height: 24px; */
    padding: 16px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }
  
  .desktop .text-wrapper-11 {
    color: var(--collor-grey-600);
    flex: 1;
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }
  
  .desktop .caret-down {
    height: 24px;
    position: relative;
    width: 24px;
  }
  
  .desktop .div-wrapper {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--collor-grey-200);
    border: 1px solid;
    border-color: var(--collor-grey-400);
    border-radius: 16px;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    min-height: 128px;
    padding: 16px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    resize: none;
  }
  
  .desktop .text-wrapper-12 {
    color: var(--collor-grey-600);
    flex: 1;
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
  }
  
  .desktop .input-2 {
    align-items: center;
    align-self: stretch;
    background-color: var(--collor-brand-500);
    border: 1px solid;
    border-color: var(--collor-grey-400);
    border-radius: 16px;
    display: flex;
    gap: 8px;
    height: 56px;
    justify-content: center;
    padding: 16px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }
  
  .desktop .text-wrapper-13 {
    color: var(--collor-grey-50);
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .desktop .contact {
    align-items: center;
    background-color: var(--collor-brand-200);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    left: 0;
    padding: 32px;
    /* position: absolute;
    top: 0; */
    position: relative;
    width: 100%;
    max-width: 448px;
    box-sizing: border-box;
  }
  
  .desktop .text-wrapper-14 {
    align-self: stretch;
    color: var(--collor-brand-900);
    font-family: "Space Grotesk", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28.8px;
    margin-top: -1px;
    position: relative;
  }
  
  .desktop .text-wrapper-15 {
    align-self: stretch;
    color: var(--collor-brand-800);
    font-family: "Space Grotesk", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    position: relative;
  }
  
  .desktop .title-pricing {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        /* left: 419px;
        position: absolute;
        top: 3014px; */
        width: 100%;
        margin-top: 160px;
        box-sizing: border-box;
        padding: 0 16px;
  }
  
  .desktop .text-wrapper-16 {
    align-self: stretch;
    color: var(--collor-brand-800);
    font-family: "Space Grotesk", Helvetica;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 44px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    max-width: 600px;
    align-self: center;
  }
  
  .desktop .text-wrapper-17 {
    align-self: stretch;
    color: var(--collor-grey-700);
    font-family: "Inter-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    text-align: center;
    max-width: 600px;
    align-self: center;
  }
  
  .desktop .princing-instance {
    left: 156px !important;
    position: absolute !important;
    top: 3246px !important;
  }
  
  .desktop .footer {
    align-items: flex-start;
    background-color: var(--collor-brand-900);
    display: flex;
    flex-direction: column;
    gap: 8px;
    left: 0;
    padding: 40px 156px;
    /* position: absolute;
    top: 5089px; */
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
  }
  
  .desktop .info-links {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 32px 0px;
    position: relative;
    width: 100%;
  }
  
  .desktop .img-2 {
    flex: 0 0 auto;
    position: relative;
    cursor: pointer;
  }
  
  .desktop .menu-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 32px;
    position: relative;
  }
  
  .desktop .text-wrapper-18 {
    color: var(--collor-brand-300);
    font-family: "Inter-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
    cursor: pointer;
  }
  
  .desktop .copyright {
    align-items: center;
    align-self: stretch;
    border-color: var(--collor-brand-800);
    border-top-style: solid;
    border-top-width: 1px;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 24px 0px 0px;
    position: relative;
    width: 100%;
  }
  
  .desktop .text-wrapper-19 {
    color: #ffffff;
    font-family: "Space Grotesk", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }

  .desktop .social-media-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  

  @media screen and (max-width: 1280px) {
    .desktop .overlap-group {
      flex-direction: column;
      align-items: center;
      height: auto;
    }
    .desktop .group {
      display: none;
    }
    .desktop .img-container {
      width: 100%;
      justify-content: flex-end;
    }
    .desktop .criar-relatorio-2 {
      position: inherit;
      width: 100%;
      height: auto;
      max-width: 900px;
      align-self: flex-end;
    }
    .desktop .frame {
      width: 100%;
      padding: 0 16px 32px 16px;
      align-items: center;
      gap: 32px;
    }
    .desktop .frame-2 {
      align-items: center;
      width: 100%;
    }
    .desktop .p {
      font-size: 32px;
      line-height: 40px;
      width: 100%;
      text-align: center;
    }
    .desktop .text-wrapper-4 {
      font-size: 20px;
      line-height: 28px;
      width: 100%;
      text-align: center;
    }
    .desktop .menu {
      flex-direction: column;
      align-items: center;
    }
    .desktop .text-wrapper-5 {
      font-size: 12px;
      width: 100%;
      text-align: center;
    }
    .desktop .container-part2 {
      flex-direction: column-reverse;
      align-items: center;
    }
    .desktop .pexels-anna {
      /* display: none; */
      width: 100%;
      height: auto;
      max-width: 600px;
      padding: 0 16px;
      margin-left: 0px;
      box-sizing: border-box;
    }
    .desktop .content-text {
      width: 100%;
      padding: 0 16px;
      align-items: center;
      box-sizing: border-box;
      padding: 0 16px 32px 16px;
    }
    .desktop .relat-rios-que {
      font-size: 24px;
      line-height: 32px;
      width: 100%;
      text-align: center;
    }
    .desktop .text-wrapper-6 {
      font-size: 24px;
      width: 100%;
      text-align: center;
    }
    .desktop .text-wrapper-7 {
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      text-align: center;
    }
    .desktop .overlap-2 {
      flex-direction: column;
      align-items: center;
      height: auto;
      margin: 64px 0 0 0;
    }
    .desktop .overlap-3 {
      width: 100%;
      margin-top: 32px;
      display: flex;
      justify-content: center;
    }
    .desktop .group-2 {
      display: none;
    }
    .desktop .solutions {
      width: 100%;
      justify-content: center;
    }
    .desktop .item-solution {
      width: 100%;
      height: auto;      
    }
    .desktop .file-text {
      width: 24px;
      height: 24px;
    }
    .desktop .title-pricing {
      margin-top: 64px;
    }
    .desktop .overlap-4 {
      margin-top: 64px;
      margin-bottom: 64px;
      flex-direction: column;
      padding: 0 16px;
      box-sizing: border-box;
    }
    .desktop .contact {
      max-width: 100%;
    }
    .desktop .text-wrapper-10 {
      font-size: 24px;
      line-height: 32px;
      width: 100%;
    }
    .desktop .overlap-group-2 {
      width: 100%;
      max-width: 100%;
    }
    .desktop .form-contact {
      width: 100%;
    }
    .desktop .footer {
      padding: 32px;
    }
    .desktop .info-links {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
    .desktop .menu-2 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 32px 0;
      gap: 24px;
    }

    .desktop .item-solution .item-line {
      display: none;
    }
    .desktop .text-wrapper-9 {
      max-width: 100%;
    }
    .desktop .item-solution .item-buttoncontainer {
      align-self: center;
      width: unset;
    }
    .desktop .solutions {
      margin-left: 0px;
      box-sizing: border-box;
      padding: 0 16px;
    }
    .desktop .item-solution .item-spacer{
      display: none;
    }
    .desktop .item-solution .item-img {
      position: unset;
      width: 516px;
      height: 576px;
    }
}