.page-preview-item {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-color: var(--collor-grey-400);
    border-radius: 4px;
    display: inline-flex;
    flex-direction: column;
    width: 31%;
    overflow: hidden;
    position: relative;
    height: 47%;
    container-type: size;
  }

  .page-preview-item.portrait6{
    width: 31%;
    height: 47%;
  }
  .page-preview-item.portrait6 .text-wrapper-2{
    margin: 0px 2.5cqw;
  }

  .page-preview-item.portrait5{
    width: 31%;
    height: 47%;
  }
  .page-preview-item.portrait5 .text-wrapper-2{
    margin: 0px 2.5cqw;
  }

  .page-preview-item.portrait4{
    width: 48%;
    height: 47%;
  }
  .page-preview-item.portrait4 .div{
    font-size: 5cqw;
    line-height: 6cqw;
  }
  /* .page-preview-item.portrait4 .number-photo{
    height: 8cqw;
    padding: 0px 2.5cqw;
  }
  .page-preview-item.portrait4 .text-wrapper-2{
    font-size: 3.5cqw;
  } */

  .page-preview-item.portrait3{
    width: 80%;
    height: 30%;
  }
  .page-preview-item.portrait3 .description{
    margin: 2%;
  }
  /* .page-preview-item.portrait3 .div{
    font-size: 3cqw;
    line-height: 3cqw;
  } */
  /* .page-preview-item.portrait3 .number-photo{
    height: 4cqw;
    padding: 0px 1cqw;
  }
  .page-preview-item.portrait3 .text-wrapper-2{
    font-size: 2.5cqw;
  } */
  

  .page-preview-item.portrait2{
    width: 100%;
    height: 47%;
  }
  .page-preview-item.portrait2 .div{
    font-size: 3cqw;
    line-height: 4cqw;
  }
  /* .page-preview-item.portrait2 .number-photo{
    height: 3cqw;
    padding: 0px 1cqw;
  }
  .page-preview-item.portrait2 .text-wrapper-2{
    font-size: 2cqw;
  } */

  .page-preview-item.portrait1{
    width: 100%;
    height: 98%;
  }
  .page-preview-item.portrait1 .div{
    font-size: 4.5cqw;
    line-height: 8cqw;
  }
  /* .page-preview-item.portrait1 .number-photo{
    height: 4cqw;
    padding: 0px 1cqw;
  }
  .page-preview-item.portrait1 .text-wrapper-2{
    font-size: 2.5cqw;
  } */

  .page-preview-item.landscape6{
    width: 31%;
    height: 47%;
  }
  .page-preview-item.landscape6 .div{
    font-size: 4cqw;
    line-height: 5cqw;
  }
  /* .page-preview-item.landscape6 .number-photo{
    height: 5cqw;
    padding: 0px;
  }
  .page-preview-item.landscape6 .text-wrapper-2{
    font-size: 3cqw;
    padding: 0px 2cqw;
  } */

  .page-preview-item.landscape5{
    width: 31%;
    height: 47%;
  }
  .page-preview-item.landscape5 .div{
    font-size: 4cqw;
    line-height: 5cqw;
  }
  /* .page-preview-item.landscape5 .number-photo{
    height: 5cqw;
    padding: 0px;
  }
  .page-preview-item.landscape5 .text-wrapper-2{
    font-size: 3cqw;
    padding: 0px 2cqw;
  } */

  .page-preview-item.landscape4{
    width: 48%;
    height: 47%;
  }
  .page-preview-item.landscape4 .div{
    font-size: 3cqw;
    line-height: 4cqw;
  }
  /* .page-preview-item.landscape4 .number-photo{
    height: 4cqw;
    padding: 0px 1.5cqw;
  }
  .page-preview-item.landscape4 .text-wrapper-2{
    font-size: 2.5cqw;
  } */

  .page-preview-item.landscape3{
    width: 32%;
    height: 98%;
  }
  .page-preview-item.landscape3 .div{
    font-size: 7cqw;
    line-height: 7cqw;
  }
  /* .page-preview-item.landscape3 .number-photo{
    height: 6cqw;
    padding: 0px 2cqw;
  }
  .page-preview-item.landscape3 .text-wrapper-2{
    font-size: 4cqw;
  } */

  .page-preview-item.landscape2{
    width: 48%;
    height: 98%;
  }
  .page-preview-item.landscape2 .div{
    font-size: 5cqw;
    line-height: 5cqw;
  }
  /* .page-preview-item.landscape2 .number-photo{
    height: 5cqw;
    padding: 0px 2cqw;
  }
  .page-preview-item.landscape2 .text-wrapper-2{
    font-size: 3cqw;
  } */

  .page-preview-item.landscape1{
    width: 100%;
    height: 98%;
  }
  .page-preview-item.landscape1 .div{
    font-size: 3cqw;
    line-height: 4cqw;
  }
  /* .page-preview-item.landscape1 .number-photo{
    height: 3cqw;
    padding: 0px 1cqw;
  }
  .page-preview-item.landscape1 .text-wrapper-2{
    font-size: 2cqw;
  } */
  
  .page-preview-item .img {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  
  .page-preview-item .overlap-group {
    height: 100%;
    position: relative;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    /* background-color: var(--collor-grey-200); */
    background-color: transparent;
  }
  
  
  .page-preview-item .description {
    align-items: flex-start;
    /* display: flex;
    flex: 0 0 auto;
    flex-direction: column; */
    margin: calc(3%);
    position: relative;
    width: calc(97%);
    box-sizing: border-box;
    height: calc(18%);
    container-type: size;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
  }
  
  .page-preview-item .div {
    align-self: stretch;
    color: var(--collor-grey-700);
    font-family: "Space Grotesk", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 12px;
    position: relative;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    display: block;
    /* display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; */
    text-align: justify;
  }
  
  .page-preview-item .number-photo {
    align-items: flex-start;
    /* background-color: var(--collor-brand-500); */
    background-color: #000000dd;
    /* border-radius: 1cqw; */
    border-radius: 3px 0 3px 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 21px;
    width: 17px;
    left: 0;
    /* padding: 0px 3.5cqw;; */
    position: absolute;
    top: 0;
  }
  
  .page-preview-item .text-wrapper-2 {
    color: #FFFFFF;
    font-family: "Space Grotesk", Helvetica;
    font-size: 9.1px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 2cqw;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .pdfBuilder .number-photo {    
    height: 42px !important;
    width: 34px !important;;    
  }
  
  .pdfBuilder .text-wrapper-2 {    
    font-size: 18px !important;
  }